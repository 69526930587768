import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  position: relative;

  & a {
    color: ${({ theme }) => theme.color.text};
    font-size: ${({ theme }) => theme.font.size.small};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Footer = () => (
  <StyledFooter>
    <div className="container d-flex justify-content-center align-items-center py-3">
      <Link to="/privacyverklaring">Privacyverklaring</Link>
      <div className="mx-3">-</div>
      <Link to="/algemene-voorwaarden" className="text-center">
        Algemene voorwaarden
      </Link>
      <div className="mx-3">-</div>
      <a href="mailto:info@mealy.nl">info@mealy.nl</a>
    </div>
  </StyledFooter>
)

export default Footer
