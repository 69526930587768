import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section className="bg-background">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <ParseContent content={fields.description || ''} />
        </div>
      </div>
    </div>
  </section>
)

export default Text
