/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Item = styled(motion.li)`
  position: relative;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > button {
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${(props) => props.theme.color.light};

    &[aria-current] {
    }

    &:hover {
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
  closeMenu: () => void
}

const ItemBorder = styled(motion.div)`
  position: absolute;
  background-color: ${(props) => props.theme.color.contrastDark};
  height: 3px;
  bottom: -5px;
  left: 0;
  border-radius: 10px;
`

const Menu: React.FC<MenuProps> = ({ fields, closeMenu }) => (
  <>
    {fields.header?.menuHeader?.map((l, index) => (
      <Item key={index} whileHover="hover" animate="rest">
        <div>
          <button
            type="button"
            onClick={() => {
              closeMenu()

              window.location.href = l?.link?.url || '/'
            }}
          >
            {l?.link?.title}
          </button>
          <ItemBorder
            variants={{
              rest: {
                opacity: 0,
                width: 0,
              },
              hover: {
                opacity: 1,
                width: '100%',
              },
            }}
          />
        </div>
      </Item>
    ))}
  </>
)

export default Menu
