import React from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledAppFunctions = styled.div`
  background-color: ${({ theme }) => theme.color.background};
`

const AppFunctionsLabel = styled.button<{ active: boolean }>`
  position: relative;
  text-align: left;

  & > span {
    color: ${({ theme, active }) =>
      !active ? theme.color.grey : theme.color.text};
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  &:after {
    background-color: ${({ theme, active }) =>
      !active ? theme.color.grey : theme.color.contrast};
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 5px;
    border-radius: 3px;
  }

  @media (min-width: 576px) {
    width: 200px;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`

const AppFunctionImageHeight = css`
  @media (min-width: 992px) {
    height: 550px;
  }

  @media (max-width: 991px) {
    height: 425px;
  }
`

const AppFunctionImagePlaceholder = styled.div`
  ${AppFunctionImageHeight};
`

const AppFunctionImage = styled(motion.div)`
  ${AppFunctionImageHeight};
  position: absolute;
  z-index: 1;
`

const AppFunctionImageCircle = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  border-radius: 50%;
  height: 400px;
  width: 400px;
  position: absolute;
  z-index: 0;
`

const AppFunctionsDescriptionWrapper = styled(motion.div)`
  overflow: hidden;
`

const AppFunctionLabel = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  color: ${({ theme }) => theme.color.light};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border-radius: 5px;
  display: inline-block;
  text-transform: uppercase;
`

const AppFunctionsDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};

  @media (min-width: 576px) {
    width: 300px;
  }
`

interface FunctionProps {
  label: string
  description: string
  image: unknown
}

interface AppFunctionsProps {}

const AppFunctions: React.FC<AppFunctionsProps> = () => {
  const [selected, setSelected] = React.useState(0)

  const {
    discoverFile,
    filterFile,
    viewFile,
    payFile,
    chatFile,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.AppFunctionsQueryQuery>(graphql`
    query AppFunctionsQuery {
      discoverFile: file(
        relativePath: { eq: "screens/mealy-app-discover.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 550, quality: 100, placeholder: NONE)
        }
      }
      filterFile: file(relativePath: { eq: "screens/mealy-app-filter.png" }) {
        childImageSharp {
          gatsbyImageData(height: 550, quality: 100, placeholder: NONE)
        }
      }
      viewFile: file(relativePath: { eq: "screens/mealy-app-view.png" }) {
        childImageSharp {
          gatsbyImageData(height: 550, quality: 100, placeholder: NONE)
        }
      }
      payFile: file(relativePath: { eq: "screens/mealy-app-pay.png" }) {
        childImageSharp {
          gatsbyImageData(height: 550, quality: 100, placeholder: NONE)
        }
      }
      chatFile: file(relativePath: { eq: "screens/mealy-app-chat.png" }) {
        childImageSharp {
          gatsbyImageData(height: 550, quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  const items: Array<FunctionProps> = [
    {
      label: 'Ontdek',
      description: `
      <h2><strong>Ga op zoek naar gerechten</strong></h2>
      <p>Ontdek Mealy om heerlijke gerechten te vinden en kom in contact met top chefs</p>
    `,
      image: discoverFile,
    },
    {
      label: 'Filter',
      description: `
      <h2><strong>Welke gerechten wil je zien?</strong></h2>
      <p>Filter op afstand, prijs, aantal personen en verzending</p>
    `,
      image: filterFile,
    },
    {
      label: 'Bekijk',
      description: `
      <h2><strong>Meer informatie over het gerecht</strong></h2>
      <p>Klik op het gerecht door meer informatie in te zien</p>
    `,
      image: viewFile,
    },
    {
      label: 'Betaal',
      description: `
      <h2><strong>Gemakkelijk betalen</strong></h2>
      <p>Betaal gemakkelijk met iDEAL, Apple Pay of creditcard</p>
    `,
      image: payFile,
    },
    {
      label: 'Chat',
      description: `
      <h2><strong>Stuur berichten naar de chef</strong></h2>
      <p>Kom in contact met de chef</p>
    `,
      image: chatFile,
    },
  ]

  return (
    <StyledAppFunctions className="py-5">
      <div className="container py-lg-5" id="welkom">
        <div className="row py-5">
          <div className="col-4 col-lg-3 d-sm-flex align-items-center justify-content-center order-1">
            <div>
              {items.map((item, index) => (
                <div key={item.label}>
                  <AppFunctionsLabel
                    type="button"
                    onClick={() => {
                      setSelected(index)
                    }}
                    active={index === selected}
                    className="py-1 my-1"
                  >
                    <span className="pe-4">{item.label}</span>
                  </AppFunctionsLabel>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center position-relative order-3 mt-lg-0 mt-5">
            <AnimatePresence>
              <AppFunctionImage
                key={selected}
                exit={{
                  scale: 0.95,
                  opacity: 0,
                }}
                initial={{
                  scale: 1.05,
                  opacity: 0,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
              >
                <Plaatjie
                  className="w-100 h-100"
                  image={{ localFile: items[selected].image }}
                  alt={items[selected].label}
                  objectFit="contain"
                />
              </AppFunctionImage>
            </AnimatePresence>
            <AppFunctionImagePlaceholder />
            <AppFunctionImageCircle />
          </div>
          <div className="col-8 col-lg-3 d-flex align-items-center justify-content-center position-relative order-lg-4 order-2">
            <div>
              <AnimatePresence>
                {selected !== null && (
                  <AppFunctionsDescriptionWrapper
                    key={selected}
                    exit={{
                      height: 0,
                    }}
                    initial={{
                      height: 0,
                    }}
                    animate={{
                      height: 'auto',
                    }}
                  >
                    <div className="py-4">
                      <AppFunctionLabel className="px-2">
                        {items[selected].label}
                      </AppFunctionLabel>
                      <AppFunctionsDescription
                        className="mt-2"
                        content={items[selected].description}
                      />
                    </div>
                  </AppFunctionsDescriptionWrapper>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </StyledAppFunctions>
  )
}

export default AppFunctions
