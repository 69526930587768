import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import GetItOn from '../Buttons/GetItOn'

const StyledBannerWithApp = styled.section`
  position: relative;
  background: rgb(168, 213, 109);
  background: linear-gradient(
    305deg,
    rgba(168, 213, 109, 1) 20%,
    rgba(98, 191, 98, 1) 85%
  );
`

const BannerWithAppBackground = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const BannerWithAppContent = styled.div`
  position: relative;
  z-index: 1;
`

const BannerWithAppContentDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  @media (min-width: 576px) {
    text-align: left;
  }

  @media (max-width: 575px) {
    text-align: center;
  }
`

const BannerWithAppMobile = styled(motion.div)`
  @media (min-width: 1200px) {
    height: 650px;
  }

  @media (max-width: 1199px) {
    height: 600px;
  }

  @media (max-width: 991px) {
    height: 500px;
  }
`

interface BannerWithAppProps {}

const BannerWithApp: React.FC<BannerWithAppProps> = () => {
  const {
    file,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.BannerWithAppQueryQuery>(graphql`
    query BannerWithAppQuery {
      file(relativePath: { eq: "screens/mealy-app-home.png" }) {
        childImageSharp {
          gatsbyImageData(height: 650, quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <StyledBannerWithApp>
      <BannerWithAppContent className="container">
        <div className="row py-5">
          <div className="col-lg-6 py-5 d-flex align-items-center">
            <div className="pt-lg-0 pt-5">
              <BannerWithAppContentDescription
                className="pe-md-5"
                content={`
                  <h1><strong>Maak het verschil<br /> in voedselverspilling<br /> met Mealy</strong></h1>
                  <p>
                    Mealy verbindt mensen om maaltijden met elkaar te delen, zo ga je
                    voedselverspilling tegen
                  </p>
                `}
              />

              <div className="mt-lg-2 d-flex align-items-center justify-content-sm-start justify-content-center">
                <GetItOn />
              </div>
            </div>
          </div>
          <div className="col-lg-6 py-lg-5 d-flex justify-content-center">
            <BannerWithAppMobile
              whileHover={{
                scale: 1.05,
              }}
            >
              <Plaatjie
                image={{ localFile: file }}
                alt="Mealy app home"
                loading="eager"
                objectFit="contain"
                className="h-100 w-100"
              />
            </BannerWithAppMobile>
          </div>
        </div>
      </BannerWithAppContent>
      <BannerWithAppBackground>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 2353.6 1112.5"
          xmlSpace="preserve"
          className="svg replaced-svg"
        >
          <path
            fill="#F5F5F5"
            d="M2353.6,0v1112.5H0c132.1-171.4,350.7-231.4,655.9-179.9c474,79.9,637.1-23.3,882.7-262.4  C1802.7,413,1870,54.1,2353.6,0z"
          />
        </svg>
      </BannerWithAppBackground>
    </StyledBannerWithApp>
  )
}

export default BannerWithApp
