/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled, { css } from 'styled-components'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 80px;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background: rgb(168, 213, 109);
      background: linear-gradient(
        305deg,
        rgba(168, 213, 109, 1) 20%,
        rgba(98, 191, 98, 1) 85%
      );
    `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  font-size: 0;

  & > svg {
    @media (min-width: 992px) {
      height: 40px;
      width: 122px;
    }

    @media (max-width: 991.98px) {
      height: 35px;
      width: 106px;
    }
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledHeader isSticky={isSticky}>
      <Container className="container">
        <Brand to="/#">
          <Logo />
          Mealy
        </Brand>

        <Mobile fields={fields} setIsScrollable={setIsScrollable} />
        <Desktop fields={fields} />
      </Container>
    </StyledHeader>
  )
}

export default Header
