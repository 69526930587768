const mainTheme = {
  color: {
    primary: '#62BF62',
    text: '#313131',
    grey: '#D1D4DD',
    dark: '#1C1C1C',
    light: '#FFFFFF',
    border: '#e7eaee',
    background: '#F5F5F5',
    contrast: '#62BF62',
    contrastLight: '#A8D56D',
    contrastDark: '#2F902F',
  },

  font: {
    family: {
      primary: 'Montserrat, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
  },
}

export default mainTheme
