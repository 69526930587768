import ParseContent from 'components/shared/ParseContent'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

const StyledPayment = styled.section`
  background-color: ${({ theme }) => theme.color.background};
  position: relative;
`

const PaymentContent = styled.div`
  position: relative;
  z-index: 1;
`

const PaymentBackground = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45%;

  & svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
`

const PaymentContentBox = styled.div`
  border-radius: 15px;
  background: rgb(168, 213, 109);
  background: linear-gradient(
    305deg,
    rgba(168, 213, 109, 1) 20%,
    rgba(98, 191, 98, 1) 85%
  );
`

const PaymentContentBoxDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
`

const PaymentContentBoxMedia = styled.div`
  position: relative;
  overflow: hidden;

  @media (min-width: 992px) {
    height: 400px;
  }

  @media (max-width: 991px) {
    height: 350px;
  }
`

interface PaymentContentBoxMediaItemProps {
  position: 'left' | 'right'
}

const PaymentContentBoxMediaItem = styled(
  motion.div
)<PaymentContentBoxMediaItemProps>`
  position: absolute;
  height: 100%;

  ${(props) =>
    props.position === 'left' &&
    css`
      left: 30px;
      z-index: 2;
      bottom: -30px;
    `};

  ${(props) =>
    props.position === 'right' &&
    css`
      right: 30px;
      z-index: 1;
      bottom: -70px;
    `};
`

interface PaymentProps {}

const Payment: React.FC<PaymentProps> = () => {
  const {
    cartFile,
    paymentFile,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.PaymentQueryQuery>(graphql`
    query PaymentQuery {
      cartFile: file(relativePath: { eq: "screens/mealy-app-cart.png" }) {
        childImageSharp {
          gatsbyImageData(height: 400, quality: 100, placeholder: NONE)
        }
      }
      paymentFile: file(relativePath: { eq: "screens/mealy-app-payment.png" }) {
        childImageSharp {
          gatsbyImageData(height: 400, quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <StyledPayment>
      <PaymentContent className="container py-5" id="betaal-gemakkelijk">
        <div className="row justify-content-center py-lg-5">
          <div className="col-lg-10">
            <PaymentContentBox className="px-lg-5">
              <div className="row">
                <div className="col-md-5 col-xl-6 py-5 d-flex align-items-center">
                  <PaymentContentBoxDescription
                    className="px-3"
                    content={`
                    <h2><strong>Betaal gemakkelijk</strong></h2>
                    <p>
                      Met iDEAL
                      <svg width="28" height="28" viewBox="0 0 88.668 79.598">
                        <path fill="none" d="M0 0h88.668v79.598H0z" />
                        <path fill="#fff" d="M7.375 7.38v64.877h37.824c24.951 0 35.771-13.973 35.771-32.502 0-18.457-10.82-32.375-35.771-32.375H7.375z" />
                        <path
                          fill="black"
                          d="M11.463 11.472h33.741c22.901 0 31.679 12.506 31.679 28.282 0 18.917-12.258 28.409-31.679 28.409H11.463V11.472zm3.271 3.271v50.151h30.47c18.396 0 28.405-8.572 28.405-25.14 0-17.027-10.85-25.012-28.405-25.012h-30.47z"
                        />
                        <path fill="black" d="M18.822 43.442h9.754v17.36h-9.754z" />
                        <Circle fill="black" cx="23.698" cy="34.695" r="6.062" />
                        <path
                          fill="#d50072"
                          d="M49.031 37.531v2.811h-6.95v-11.28h6.728v2.81h-3.918v1.306h3.705v2.811h-3.705v1.543l4.14-.001zm1.216 2.813l3.401-11.286h3.998l3.4 11.286h-2.925l-.638-2.183h-3.676l-.639 2.183h-2.921zm4.384-4.991h2.034l-.933-3.188h-.17l-.931 3.188zm7.828-6.294h2.812v8.473h4.162c-1.142-15.374-13.234-18.696-24.228-18.696H33.488v10.229h1.734c3.162 0 5.125 2.144 5.125 5.594 0 3.56-1.916 5.685-5.125 5.685h-1.734V60.81h11.718c17.866 0 24.088-8.298 24.308-20.468h-7.055V29.059zm-28.973 2.816v5.657h1.736c1.203 0 2.315-.348 2.315-2.874 0-2.467-1.237-2.783-2.315-2.783h-1.736z"
                        />
                      </svg>, Apple Pay
                      <svg style="height: 18px; width: 18px;" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 502.657 502.657" style="enable-background:new 0 0 502.657 502.657;" xml:space="preserve">
                        <g>
                          <g>
                            <path d="M389.063,281.758c-13.827-69.587,52.223-110.399,52.223-110.399s-25.734-37.231-68.983-46.809
                              c-43.228-9.599-64.863,2.394-86.456,11.389c-21.614,9.017-32.399,9.017-32.399,9.017c-31.191,0-53.992-33.025-113.419-17.99
                              c-40.941,10.332-84.04,57.594-91.827,114.002c-7.787,56.429,9.017,128.432,41.416,184.236
                              c32.399,55.847,65.403,76.835,88.828,77.439c23.426,0.582,46.809-16.782,75.002-21.01c28.215-4.185,45.579,10.225,73.254,18.012
                              c27.567,7.766,37.231,0.561,68.983-26.446c31.838-27.007,60.657-104.467,60.657-104.467S402.89,351.388,389.063,281.758z"/>
                            <path d="M327.845,79.531C358.475,48.319,348.1,0,348.1,0s-43.012,7.55-71.831,37.533c-28.797,30.005-24.03,77.396-24.03,77.396
                              S297.214,110.723,327.845,79.531z"/>
                          </g>
                        </g>
                      </svg>
                      of creditcard
                      <svg width="29.094" height="18.856" viewBox="0 0 29.094 18.856">
                        <g transform="translate(0 -132.862)">
                          <path
                            d="M28.916,149.519V135.061a2.2,2.2,0,0,0-2.2-2.2H2.2a2.2,2.2,0,0,0-2.2,2.2v14.457a2.2,2.2,0,0,0,2.2,2.2H26.717A2.2,2.2,0,0,0,28.916,149.519Z"
                            fill="#007ad8"
                          />
                          <path
                            d="M413.93,132.862h-1.959a2.2,2.2,0,0,1,2.2,2.2v14.457a2.2,2.2,0,0,1-2.2,2.2h1.959a2.2,2.2,0,0,0,2.2-2.2V135.061A2.2,2.2,0,0,0,413.93,132.862Z"
                            transform="translate(-387.035)"
                            fill="#007ad8"
                          />
                          <g transform="translate(3.032 146.688)">
                            <path
                              d="M64.328,362.237H50.566a.471.471,0,1,1,0-.943H64.328a.471.471,0,1,1,0,.943Z"
                              transform="translate(-50.095 -361.294)"
                              fill="#c0d6f2"
                            />
                          </g>
                          <g transform="translate(3.032 148.47)">
                            <path
                              d="M57.447,391.667H50.566a.471.471,0,1,1,0-.943h6.881a.471.471,0,1,1,0,.943Z"
                              transform="translate(-50.095 -390.724)"
                              fill="#c0d6f2"
                            />
                          </g>
                          <g transform="translate(3.032 143.327)">
                            <g transform="translate(7.838 0)">
                              <path
                                d="M180.053,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-179.582 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(9.975 0)">
                              <path
                                d="M215.367,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-214.896 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(12.112 0)">
                              <path
                                d="M250.682,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-250.211 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(14.25 0)">
                              <path
                                d="M286,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-285.526 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(17.812 0)">
                              <path
                                d="M344.854,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-344.383 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(19.95 0)">
                              <path
                                d="M380.169,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-379.698 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(22.088 0)">
                              <path
                                d="M415.484,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-415.013 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(0 0)">
                              <path
                                d="M50.566,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-50.095 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(2.138 0)">
                              <path
                                d="M85.881,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-85.41 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                            <g transform="translate(4.275 0)">
                              <path
                                d="M121.2,307.829a.471.471,0,0,1-.471-.471v-1.131a.471.471,0,0,1,.943,0v1.131a.471.471,0,0,1-.471.471Z"
                                transform="translate(-120.724 -305.756)"
                                fill="#e2e2e2"
                              />
                            </g>
                          </g>
                          <path
                            d="M63.955,184.391H58.587a.706.706,0,0,1-.706-.706v-4.238a.706.706,0,0,1,.706-.706h5.368a.706.706,0,0,1,.706.706v4.238A.706.706,0,0,1,63.955,184.391Z"
                            transform="translate(-54.378 -43.102)"
                            fill="#ffd15b"
                          />
                          <path
                            d="M56.639,170.954H51.272a1.179,1.179,0,0,0-1.178,1.178v4.238a1.179,1.179,0,0,0,1.178,1.178h5.368a1.179,1.179,0,0,0,1.178-1.178v-4.238a1.179,1.179,0,0,0-1.178-1.178Zm.235,1.178v1.648H54.427V171.9h2.213A.235.235,0,0,1,56.874,172.132Zm-5.838,4.238v-4.238a.235.235,0,0,1,.235-.235h2.213V176.6H51.272A.235.235,0,0,1,51.037,176.369Zm5.6.235H54.427v-1.883h2.448v1.648A.235.235,0,0,1,56.639,176.6Z"
                            transform="translate(-47.062 -35.786)"
                            fill="#feb137"
                          />
                          <path
                            d="M349.965,242.831h-4.776a.251.251,0,0,1-.251-.251V241.2a.251.251,0,0,1,.251-.251h4.776a.251.251,0,0,1,.251.251v1.382a.251.251,0,0,1-.251.251Z"
                            transform="translate(-324.06 -101.542)"
                            fill="#fd4755"
                          />
                        </g>
                      </svg>
                    </p>
                  `}
                  />
                </div>
                <div className="col-md-7 col-xl-6 pt-md-5">
                  <PaymentContentBoxMedia>
                    <PaymentContentBoxMediaItem
                      position="left"
                      initial={{
                        rotate: '352deg',
                      }}
                      whileHover={{
                        zIndex: 3,
                        scale: 1.1,
                        rotate: '360deg',
                      }}
                    >
                      <Plaatjie
                        image={{ localFile: cartFile }}
                        alt="Mealy winkelmand"
                        objectFit="contain"
                      />
                    </PaymentContentBoxMediaItem>
                    <PaymentContentBoxMediaItem
                      position="right"
                      initial={{
                        rotate: '8deg',
                      }}
                      whileHover={{
                        zIndex: 3,
                        scale: 1.1,
                        rotate: '0deg',
                      }}
                    >
                      <Plaatjie
                        image={{ localFile: paymentFile }}
                        alt="Mealy betalen"
                        objectFit="contain"
                      />
                    </PaymentContentBoxMediaItem>
                  </PaymentContentBoxMedia>
                </div>
              </div>
            </PaymentContentBox>
          </div>
        </div>
      </PaymentContent>
      <PaymentBackground>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#F5F5F5"
            fillOpacity="1"
            d="M0,192L26.7,170.7C53.3,149,107,107,160,96C213.3,85,267,107,320,133.3C373.3,160,427,192,480,208C533.3,224,587,224,640,213.3C693.3,203,747,181,800,149.3C853.3,117,907,75,960,69.3C1013.3,64,1067,96,1120,122.7C1173.3,149,1227,171,1280,197.3C1333.3,224,1387,256,1413,272L1440,288L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"
          />
        </svg>
      </PaymentBackground>
    </StyledPayment>
  )
}

export default Payment
