/* eslint-disable no-alert */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Media
import GetItOnAppStore from 'img/get-it-on-app-store.inline.svg'
import GetItOnGooglePlay from 'img/get-it-on-google-play.inline.svg'

const StyledGetItOn = styled(motion.button)`
  & img,
  & svg {
    @media (min-width: 992px) {
      height: 150px;
    }

    @media (max-width: 991px) {
      height: 125px;
    }
  }
`

interface GetItOnProps {}

const GetItOn: React.FC<GetItOnProps> = () => (
  <>
    <StyledGetItOn
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      type="button"
      onClick={() => {
        window.open(
          'http://play.google.com/store/apps/details?id=nl.mealy.app',
          '_blank'
        )
      }}
    >
      <GetItOnGooglePlay />
    </StyledGetItOn>

    <StyledGetItOn
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      type="button"
      onClick={() => {
        window.open(
          'https://apps.apple.com/nl/app/mealy/id1597475730',
          '_blank'
        )
      }}
      className="ms-4"
    >
      <GetItOnAppStore />
    </StyledGetItOn>
  </>
)

export default GetItOn
