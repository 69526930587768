import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

const StyledBannerText = styled.section`
  position: relative;
  background: rgb(168, 213, 109);
  background: linear-gradient(
    305deg,
    rgba(168, 213, 109, 1) 20%,
    rgba(98, 191, 98, 1) 85%
  );
  min-height: 300px;
`

const BannerTextBackground = styled.div`
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const BannerTextContent = styled.div`
  position: relative;
  z-index: 1;
`

const BannerTextContentDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
`

interface BannerTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const BannerText: React.FC<BannerTextProps> = ({ fields }) => (
  <StyledBannerText>
    <BannerTextContent className="container">
      <div className="row py-5">
        <div className="col-lg-12 py-5 d-flex align-items-center">
          <BannerTextContentDescription
            className="pe-5 pt-lg-0 pt-5 w-100"
            content={fields?.description || ''}
          />
        </div>
      </div>
    </BannerTextContent>
    <BannerTextBackground>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#F5F5F5"
          fillOpacity="1"
          d="M0,224L60,240C120,256,240,288,360,293.3C480,299,600,277,720,266.7C840,256,960,256,1080,224C1200,192,1320,128,1380,96L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        />
      </svg>
    </BannerTextBackground>
  </StyledBannerText>
)

export default BannerText
