import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Lottie
import LottieView, { LottieProps } from 'react-lottie'

// Animations
import * as dishEat from 'img/animations/dish-eat.json'
import dishBuy from 'img/animations/dish-buy.json'
import dishOrder from 'img/animations/dish-order.json'
import dishReview from 'img/animations/dish-review.json'

const StyledHowItWorks = styled.section`
  background-color: ${({ theme }) => theme.color.background};
`

const StyledHowItWorksDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
`

interface ItemProps {
  key: string
  duration: number
  timeToStop: number
  description: string
  image: unknown
  isReview?: boolean
}

const items: Array<ItemProps> = [
  {
    key: 'dish-eat',
    duration: 6000,
    timeToStop: 5000,
    description: `
      <h3><strong>Wat eten we vandaag?</strong></h3>
      <p>Zoek naar gerechten of start met delen</p>
    `,
    image: dishEat,
  },
  {
    key: 'dish-buy',
    duration: 6000,
    timeToStop: 3500,
    description: `
      <h3><strong>Plaats je bestelling</strong></h3>
      <p>Betaal gemakkelijk via iDEAL, Apple Pay of creditcard</p>
    `,
    image: dishBuy,
  },
  {
    key: 'dish-order',
    duration: 7000,
    timeToStop: 5500,
    description: `
      <h3><strong>Eetsmakelijk!</strong></h3>
      <p>Geniet van gerechten bij jou in de buurt</p>
    `,
    image: dishOrder,
  },
  {
    key: 'dish-review',
    duration: 4000,
    timeToStop: 2500,
    description: `
      <h3><strong>Geef je mening</strong></h3>
      <p>Zo kunnen wij onze service en gerechten verbeteren</p>
    `,
    image: dishReview,
    isReview: true,
  },
]

interface HowItWorksProps {}

const HowItWorks: React.FC<HowItWorksProps> = () => (
  <StyledHowItWorks>
    <div className="container py-lg-5" id="hoe-werkt-het">
      <StyledHowItWorksDescription
        className="pt-5"
        content={`
          <h2 style="text-align: center;"><strong>Hoe het werkt</strong></h2>
        `}
      />

      <div className="row gx-md-5 mt-4 pb-5">
        {items.map((item) => (
          <div className="col-6 col-lg-3" key={item.key}>
            <HowItWorksStep
              key={item.key}
              duration={item.duration}
              timeToStop={item.timeToStop}
              options={{
                loop: true,
                autoplay: true,
                animationData: item.image,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              description={item.description}
              isReview={!!item.isReview}
            />
          </div>
        ))}
      </div>
    </div>
  </StyledHowItWorks>
)

const StyledHowItWorksStep = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.background};
  border: 1px solid transparent;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
`

const HowItWorksStepReviewBackground = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HowItWorksStepDescription = styled(ParseContent)`
  & p,
  & h3 {
    text-align: center;
  }
`

interface HowItWorksStepProps extends LottieProps {
  duration: number
  timeToStop: number
  description: string
  isReview?: boolean
}

const HowItWorksStep: React.FC<HowItWorksStepProps> = ({
  options,
  description,
  isReview = false,
  duration,
  timeToStop,
}) => {
  const [isPaused, setPaused] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setPaused(true)
    }, timeToStop)
  }, [])

  const animate = () => {
    if (!isPaused) {
      return
    }

    setPaused(false)

    setTimeout(() => {
      setPaused(true)
    }, duration)
  }

  return (
    <StyledHowItWorksStep
      whileHover={{
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px 0 rgb(44, 46, 48, .08)',
        border: '1px solid #e7eaee',
      }}
      onMouseEnter={() => {
        animate()
      }}
      onClick={() => {
        animate()
      }}
      className="px-lg-2 py-lg-3"
    >
      <div className="d-flex align-items-center">
        {isReview && (
          <div
            style={{ height: 200, width: 200 }}
            className="d-flex align-items-center mx-auto justify-content-center"
          >
            <HowItWorksStepReviewBackground>
              <LottieView
                options={options}
                height={19}
                width={100}
                isPaused={isPaused}
              />
            </HowItWorksStepReviewBackground>
          </div>
        )}
        {!isReview && (
          <LottieView
            options={options}
            height={200}
            width={200}
            isPaused={isPaused}
          />
        )}
      </div>
      <HowItWorksStepDescription content={description} />
    </StyledHowItWorksStep>
  )
}

export default HowItWorks
