import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import GetItOn from '../Buttons/GetItOn'

const StyledEasyPost = styled.section`
  background-color: ${({ theme }) => theme.color.background};
  position: relative;

  & svg.bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
  }
`

const EasyPostDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
`

const EasyPostApp = styled(motion.div)`
  position: relative;
  z-index: 1;

  @media (min-width: 1200px) {
    height: 650px;
  }

  @media (max-width: 1199px) {
    height: 600px;
  }

  @media (max-width: 991px) {
    height: 450px;
  }
`

const EasyPostAppBackground = styled.div`
  background-color: ${({ theme }) => theme.color.contrast};
  border-radius: 50%;
  width: 400px;
  height: 400px;
  position: absolute;
  z-index: 0;
`

interface EasyPostProps {}

const EasyPost: React.FC<EasyPostProps> = () => {
  const {
    file,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.EasyPostQueryQuery>(graphql`
    query EasyPostQuery {
      file(relativePath: { eq: "screens/mealy-app-post.png" }) {
        childImageSharp {
          gatsbyImageData(height: 650, quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <StyledEasyPost>
      <svg
        className="bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#ffffff"
          fillOpacity="1"
          d="M0,256L48,218.7C96,181,192,107,288,74.7C384,43,480,53,576,64C672,75,768,85,864,80C960,75,1056,53,1152,69.3C1248,85,1344,139,1392,165.3L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        />
      </svg>
      <div className="container py-5" id="gerecht-plaatsen">
        <div className="row py-lg-5">
          <div className="col-lg-6 d-flex align-items-center pt-lg-0 pt-5">
            <div>
              <EasyPostDescription
                className="pe-5"
                content={`
                  <h2><strong>Plaats je gerecht snel en gemakkelijk</strong></h2>
                  <p>
                    Door middel van een stappenformulier is je gerecht zo geplaatst! Spaar je winst op om vervolgens uit te betalen
                  </p>
                `}
              />

              <div className="mt-2 d-flex align-items-center">
                <GetItOn />
              </div>
            </div>
          </div>
          <div className="col-lg-6 position-relative d-flex align-items-center justify-content-center mt-lg-0 mt-4">
            <EasyPostApp
              whileHover={{
                scale: 1.05,
              }}
            >
              <Plaatjie
                image={{ localFile: file }}
                alt="Mealy makkelijk plaatsen"
                objectFit="contain"
                className="h-100 w-100"
              />
            </EasyPostApp>
            <EasyPostAppBackground />
          </div>
        </div>
      </div>
    </StyledEasyPost>
  )
}

export default EasyPost
