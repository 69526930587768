import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Elements
import GetItOn from 'components/elements/Buttons/GetItOn'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

const StyledDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;

  @media (min-width: 1200px) {
    margin-left: 50px;
  }
`

const CTA = styled.button`
  background-color: #f36f20;
  border-radius: 5px;
  color: ${({ theme }) => theme.color.light};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const CTABox = styled(motion.div)`
  width: 400px;
  height: 100px;
  right: 0;
  top: calc(100% + 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(168, 213, 109);
  background: linear-gradient(
    305deg,
    rgba(168, 213, 109, 1) 20%,
    rgba(98, 191, 98, 1) 85%
  );
  border-radius: 25px;
  box-shadow: 0 5px 8px rgb(0 0 0 / 25%);
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
}

const Desktop: React.FC<DesktopProps> = ({ fields }) => {
  const [isOpen, setOpen] = React.useState(false)

  return (
    <StyledDesktop className="d-none d-lg-flex">
      <Menu fields={fields} closeMenu={() => null} />
      <div className="position-relative">
        <CTA
          type="button"
          className="px-3 py-2"
          onClick={() => {
            setOpen(!isOpen)
          }}
        >
          Download Mealy nu!
        </CTA>
        <AnimatePresence>
          {isOpen && (
            <CTABox
              animate={{
                y: 0,
                opacity: 1,
              }}
              initial={{
                y: -30,
                opacity: 0,
              }}
              exit={{
                y: -30,
                opacity: 0,
              }}
              className="position-absolute"
            >
              <GetItOn />
            </CTABox>
          )}
        </AnimatePresence>
      </div>
    </StyledDesktop>
  )
}

export default Desktop
