import React from 'react'

// Fragments
import BannerWithApp from 'components/elements/page/BannerWithApp'
import HowItWorks from 'components/elements/page/HowItWorks'
import Payment from 'components/elements/page/Payment'
import WhyMealy from 'components/elements/page/WhyMealy'
import EasyPost from 'components/elements/page/EasyPost'
import AppFunctions from 'components/elements/page/AppFunctions'

interface HomeProps {}

const Home: React.FC<HomeProps> = () => (
  <>
    <BannerWithApp />

    <AppFunctions />

    <HowItWorks />

    <Payment />

    <WhyMealy />

    <EasyPost />
  </>
)

export default Home
