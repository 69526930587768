import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Media
import Order from 'img/order.inline.svg'
import Recycle from 'img/recycle.inline.svg'
import Snap from 'img/snap.inline.svg'

const StyledWhyMealy = styled.section`
  position: relative;
`

const WhyMealyDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
`

interface WhyMealyProps {}

const WhyMealy: React.FC<WhyMealyProps> = () => (
  <StyledWhyMealy>
    <div className="container py-lg-5" id="waarom-mealy">
      <WhyMealyDescription
        className="pt-5"
        content={`
          <h2 style="text-align: center;"><strong>Waarom <strong>Mealy?</strong></h2>
        `}
      />

      <div className="row pb-5 gx-3 gx-lg-5">
        <div className="col-sm-4 mt-4">
          <WhyMealyItem
            icon={Snap}
            description={`
              <h3><strong>Laagdrempelig</strong></h3>
              <p>Aanmelden (via socials of email), inloggen en gerechten bestellen. Je kunt direct een gerecht plaatsen en later je portomonnee uitbetalen</p>
            `}
          />
        </div>
        <div className="col-sm-4 mt-4">
          <WhyMealyItem
            icon={Recycle}
            description={`
              <h3><strong>Milieu vriendelijk</strong></h3>
              <p>Het idee en de visie achter de app is om een verschil te kunnen maken voor het milieu en een verandering te maken in het leven van mensen, die altijd druk bezig zijn</p>
            `}
          />
        </div>
        <div className="col-sm-4 mt-4">
          <WhyMealyItem
            icon={Order}
            description={`
              <h3><strong>Gratis</strong></h3>
              <p>De app zelf vind je gratis in de app store. Download het en maak een account aan. Help ons om deze gemeenschap een betere plek te maken!</p>
            `}
          />
        </div>
      </div>
    </div>
  </StyledWhyMealy>
)

const StyledWhyMealyItem = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.light};
  border: 1px solid ${({ theme }) => theme.color.border};
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(44 46 48 / 8%);
  height: 100%;
`

const WhyMealyItemDescription = styled(ParseContent)`
  position: relative;
  z-index: 1;

  & h3 {
    color: ${({ theme }) => theme.color.text};
  }

  & h3,
  & p {
    text-align: center;
  }
`

const WhyMealyItemIcon = styled.div`
  background-color: ${({ theme }) => theme.color.contrastLight};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    & path,
    & circle,
    & rect {
      fill: ${({ theme }) => theme.color.contrastDark};
    }

    @media (min-width: 992px) {
      height: 50px;
      width: 50px;
    }

    @media (max-width: 991px) {
      height: 40px;
      width: 40px;
    }
  }

  @media (min-width: 992px) {
    height: 100px;
    width: 100px;
  }

  @media (max-width: 991px) {
    height: 75px;
    width: 75px;
  }
`

interface WhyMealyItemProps {
  description: string
  icon: React.FC
}

const WhyMealyItem: React.FC<WhyMealyItemProps> = ({
  description,
  icon: Icon,
}) => (
  <StyledWhyMealyItem
    className="p-xl-5 p-lg-4 px-2 py-3"
    whileHover={{
      scale: 1.05,
    }}
  >
    <div className="d-flex justify-content-center">
      <WhyMealyItemIcon>
        <Icon />
      </WhyMealyItemIcon>
    </div>
    <WhyMealyItemDescription className="mt-lg-5 mt-4" content={description} />
  </StyledWhyMealyItem>
)

export default WhyMealy
